.accordion-wrapper .accordion-item {
  background-color: transparent;
  border: none;
}

.accordion-wrapper .accordion-item h3.accordion-title {
  font-size: 1.75rem;
  margin-left: 10%;
  width: 80%;
}

.accordion-wrapper .accordion-item h3.accordion-title .title-wrapper {
  color: black;
}

.accordion-wrapper .accordion-item h3.accordion-title button {
  position: relative;
  display: flex;
  align-items: center;
  background: #fbdd97;
  border-radius: 1rem;
  border: 4px solid black;
  font-size: 1.5rem;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.accordion-wrapper .accordion-item h3.accordion-title button.active {
  background-color: #fbdd97;
  color: transparent;
  border: 4px solid black;
}

.accordion-wrapper .accordion-item h3.accordion-title button:hover {
  background-color: #dfbd85;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.title-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper {
  width: 10%;
  display: flex;
  justify-content: center;
}


.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.minus {
  content: url('../images/minus.png');
  width: 40px;
  height: 40px;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.plus {
  content: url('../images/plus.png');
  width: 40px;
  height: 40px;
  transform: rotate(90deg);
  transition-timing-function: ease-in;
  transition: all 1s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-close { 
  background: transparent;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0 1rem;
  opacity:0;
  width:80%;
  height:0;
  overflow: hidden;
  transition-timing-function: ease-out;
  transition: all 1s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-open {
  overflow: hidden;
  background: #ef6256;
  padding: 2rem 1rem 1rem 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  border: 4px solid black;
  opacity:1;
  width: 80%;
  height: auto;
  margin-top: -2rem;
  margin-left: 10%;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  color: black;
  font-size: 1.0rem;
  font-family: "Baloo 2";
}

.accordion-wrapper .accordion-item .accordion-panel p {
  margin: 0;
}