.name-card {
  background-color: #ffe58b;
  border: 0.5vw solid black;
  border-radius: 20px;
  height: 100%;
}
.name-card .img {
  width: 84%;
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 10%;
  margin-bottom: 3%;
  border: 0.5vw solid black;
  border-radius: 20px;
}
.name-card .name {
  width: 84%;
  text-align: center;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: 2vw;
}
.name-card .title {
  width: 84%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 30px;
  font-size: 1.7vw;
  text-align: center;
  margin: auto;
  border: 0.5vw solid black;
  border-radius: 2.8vw;
  background-color: #fcf3e4;
}
.name-card .desc {
  width: 80%;
  margin: auto;
  text-align: left;
  font-size: 1.3vw;
  font-family: "Baloo 2";
  font-weight: lighter;
  padding-top: 6%;
  padding-bottom: 10%;
}