@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&display=swap');
* {
  font-family: "Baloo 2", cursive;
  font-weight: bold;
}
.nav-scroll-header {
  margin-top: -5px;
}
.nav-link {
  color: #ef6256;
}
.header-text-1 {
  font-family: "Baloo 2", cursive;
  font-weight: bold;
  font-size: 2vw;
  color: #fbdd97;
  height: 20%;
  margin-left: 5vw;
  margin-top: -2vh;
}
.header-text-2 {
  font-family: "Baloo 2", cursive;
  font-weight: bold;
  font-size: 2vw;
  color: #fbdd97;
  height: 20%;
  margin-left: 5vw;
  margin-top: -2vh;
}
.header-text-3 {
  font-family: "Baloo 2", cursive;
  font-weight: bold;
  font-size: 2vw;
  color: #fbdd97;
  height: 20%;
  margin-left: 7.5vw;
  margin-top: -2vh;
}
.header-stroke-solid {
  -webkit-text-stroke: 6px #ef6256;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.header-text-solid {
  color: #fbdd97;
  position: absolute;
}
.header-text-solid::before {
  content: attr(text);
  position: absolute;
  color: white;
  -webkit-mask: linear-gradient(to bottom,transparent,white);
}