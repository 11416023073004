@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&display=swap');
.background {
  background-size: cover;
  background-position: bottom center;
  background-repeat: repeat-y;
}
.front-desk {
  display: grid;
  background-color: transparent;
  width: 100%;
  align-items: center;
  margin: auto;
  grid-template-columns: 1fr 1.5fr; 
  margin-top: 0%;
  margin-bottom: 6%;
}
.shopping-window {
  width: 100%;
  margin: auto;
}
.release-state {
  font-family: "Baloo 2", cursive;
  font-weight: bold;
  font-size: 4vw;
  color: white;
  height: 20%;
}
.release-start {
  color: white;
  font-size: 5vw;
  font-weight: bold;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}
.countdown {
  color: #ef6256;
  font-size: 3vw;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  align-items: center;
}
.countdown-title {
  text-align: center;
}
.time-card {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  margin: auto;
  justify-content: center;
  align-items: center;
}
.flip-cards {
  display: grid;
  justify-content: center;
  align-items: center;
}
.tick-flip-panel {
  color: white;
}
.tick-flip-panel {
  background-color: #ef6256;
}
.time-card-text {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2vw;
  color: white;
}
.project-counter {
  font-family: "Baloo 2", cursive;
  font-weight: bold;
  font-size: 5vw;
  width: 100%;
  display: grid;
  margin-top: 10%;
  margin-bottom: 5%;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}
.stroke-solid {
  -webkit-text-stroke: 1vw #ef6256;
  position: absolute;
}
.text-solid {
  color: white;
  position: absolute;
}
.wallet-status {
  position: relative;
  margin-top: 5vh;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  background-color: #fbdd97;
  border:#ef6256 solid;
  border-width: 0.3vw;
  border-radius: 2vh;
  padding-top: 1vw;
  padding-bottom: 1vw;
}
.wallet-status .wallet-item {
  margin-bottom: 0%;
  padding-top: 0px;
  padding-left: 4vw;
  padding-right: 4vw;
  font-size: 1.6vw;
  color: rgb(89, 88, 88);
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr; 
  /* justify-content: center;
  display: flex; */
}
#input-area {
  margin-bottom: 2%;
}
#mintcnt-slider {
  margin-top: 3vh;
  margin-bottom: 2vh;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  justify-content: center;
  width: 50%;
  height: 1vw;
  -webkit-appearance: none;
  flex: 7;
  border-radius: 1vw;
  background: #ef6256;
  /* overflow: hidden; */
  /* outline: 0.1vw solid #ef6256; */
}
#mintcnt-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3.5vw;
  height: 3.5vw;
  border-radius: 1.5vw;
  cursor: pointer;
  background: white;
  /* border-width: 0.3vw; */
  /* box-shadow: inset 0px 0px 0.4vw 0.4vw #ef6256; */
}
#mintcnt-slider::-webkit-slider-runnable-track{
  -webkit-appearance: none;
}
#mintcnt-slider::-moz-range-thumb {
  width: 2vw;
  height: 2vw;
  border-radius: 0.7vw;
  cursor: pointer;
  background: #413f3e;
  color: black;
}
#price-window {
  width: 50%;
  grid-template-columns: 1fr 2fr; 
  margin-left: 1vw;
  display: grid;
  text-align: center;
  margin: auto;
}
.mint-state {
  color: #ef6256;
  font-size: 2.0vw;
  margin: 0;
}
.trade-button {
  margin-top: 2vh;
  width: 50%;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  padding: 1vw 1vw 1vw 1vw;
  font-family: "Baloo 2", cursive;
  font-size: 2vw;
  color: #ef6256;
  /* line-height: 15px; */
  border: 0.5vw solid #ef6256;
  border-radius: 30px;
  background:  linear-gradient(to bottom,#fbdd97,white);
  transition: all 0.3s ease 0s;
  cursor: pointer;
}  
.trade-button:hover {
  color: #FFF;
  background: #ef6256;
  border: 6px solid #ef6256;
}
#status-area {
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  color: #de2b1a;
}
.introduction {
  display: grid;
  background-color: transparent;
  width: 100%;
  align-items: center;
  color: brown;
  margin: 0 auto;
  grid-template-columns: 1.4fr 1fr;
  grid-gap: 0%;   
}
.intro-text {
  color: rgb(52, 51, 51);
  margin-left: 10%;
  margin-right: 0%;
  font-size: 1.5vw;
  font-family: "Baloo 2";
}
.showroom {
  display: grid;
  background-color: transparent;
  height: 100%;
  width: 80%;
  margin: 3% 10%;
}
.example_img {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}
.roadmap-img {
  width: 100%;
}
.team-members {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2.5vw;
  width: 90%;
  margin: auto;
  margin-top: 2%;
  align-items: center;
}
.section-title{
  margin-top: 5%;
  font-family: "Baloo 2", cursive;
  font-size: 8vw;
  font-weight: bold;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}
.stroke {
  -webkit-text-stroke: 1.2vw #ef6256;
  text-shadow: black 1.2vh 1.2vw 0px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.text {
  color: #fbdd97;
  position: absolute;
}
.text::before {
  content: attr(text);
  position: absolute;
  color: white;
  -webkit-mask: linear-gradient(to bottom,transparent,white);
}
.question {
  margin-top: 1%;
  margin-bottom: 1%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.question-card {
  background-color: #fbdd97;
}
.answer-card {
  background-color: #ef6256;
  color: black;
  font-size: 1vw;
}
.bottom_blank {
  height: 5%;
}